/*
 * ************************************************************************************************
 * Import internal dependencies
 * ************************************************************************************************
 */

import {
	getContentHashFromPath,
	saveLocalStorage,
	getLocalStorage,
	validateLocalStorage,
} from '../modules/utilities';

/*
 * **************************************************************************************************
 * Begin Quick Search Class
 * **************************************************************************************************
 */

export class QuickSearch {
	/*
	 * Class constructor function.
	 */
	constructor(options) {
		/* Define this.options */
		this.options = options;

		/*
		 * Check for require class options
		 */
		if (!this.options || !this.options.searchInput) {
			throw new Error(
				`Error: Required options not set:\n ${JSON.stringify(
					this.options
				)}`
			);
		}

		/*
		 * Define this.options.quickSearchDataUrlAttribute with a default value if needed.
		 */
		this.options.quickSearchDataUrlAttribute =
			this.options.quickSearchDataUrlAttribute || 'data-bsu-quicksearch';

		/*
		 * Define this.options.parentContainer with a default value if needed.
		 */
		this.options.parentContainer =
			this.options.parentContainer || '.bsu-search';

		/*
		 * Define this.options.resultsContainer
		 * Assign a default value if needed.
		 */
		this.options.resultsContainer =
			this.options.resultsContainer || 'bsu-quicksearch-results';

		/*
		 * Define this.searchInput
		 * The search input element.
		 */
		this.searchInput = this.options.searchInput;

		/*
		 * Find the closest ancestor with the class 'collapse'.
		 * Returns null if not found.
		 */
		this.collapsingContainer = this.findParentWithClass(
			this.searchInput,
			'collapse'
		);

		/*
		 * Define this.parentContainerElement
		 * parentContainer element from the DOM.
		 */
		this.parentContainerElement = this.searchInput.closest(
			this.options.parentContainer
		);

		/*
		 * Define resultsContainerElement.
		 */
		this.resultsContainerElement =
			this.parentContainerElement.querySelector(
				'.' + this.options.resultsContainer
			);

		/*
		 * Define quickSearchDataUrl
		 * The file to load the quichSearch data from.
		 */
		const quickSearchDataUrl = this.searchInput.getAttribute(
			this.options.quickSearchDataUrlAttribute
		);

		/*
		 * Add passive resize eventListener
		 * Adjust the dimensions of the quicksearch container when the window is resized.
		 * Bind this instance of the class to the function.
		 */
		window.addEventListener(
			'resize',
			function () {
				this.setQuickSearchResultsDimensions();
			}.bind(this),
			{ passive: true }
		);

		/*
		 * Add passive scroll eventListener
		 * Adjust the dimensions of the quicksearch container when the window is resized.
		 * Bind this instance of the class to the function.
		 */
		window.addEventListener(
			'scroll',
			function () {
				this.setQuickSearchResultsDimensions();
			}.bind(this),
			{ passive: true }
		);

		/*
		 * Define quickSearchHash
		 * The Quicksearch hash value from the quickSearchDataUrl.
		 */
		const quickSearchHash = getContentHashFromPath(quickSearchDataUrl);

		/* Define quickSearchData
		 * The Quicksearch data.
		 */
		let quickSearchData;

		/*
		 * Check if the quickSearchHash is valid.
		 * If it is not, get the new data and store it locally.
		 * If it is use, the local data.
		 */
		if (!validateLocalStorage(quickSearchHash, 'quicksearchHash')) {
			fetch(quickSearchDataUrl)
				.then((response) => {
					/* Check of the network response failed and throw a new error to the console and exit the script. */
					if (!response.ok) {
						throw new Error(
							// eslint-disable-next-line no-undef
							`Quicksearch - Network response failed for the following element:\nsearchInput Element: ${this.searchInput.outerHTML}\n${error}`
						);
					}
					return response.json();
				})
				.then((data) => {
					/*
					 * Update quickSearchData with the fetch response data so it can be used later.
					 */
					quickSearchData = data;

					/*
					 * Get the quickSearchData and save it and the current quicksearchHash to local storage.
					 */
					saveLocalStorage(
						'quickSearchData',
						JSON.stringify(quickSearchData)
					);
					saveLocalStorage('quicksearchHash', quickSearchHash);

					/*
					 * Call addSearchEventListener to setup the search.
					 */
					this.addSearchEventListener(quickSearchData);
				})
				.catch((error) => {
					/* Throw a new error to the console and exit the script. */
					throw new Error(
						`Quicksearch - Unable to retrieve the data from the quicksearch.json defined in the following element:\nsearchInput Element: ${this.searchInput.outerHTML}\n${error}`
					);
				});
		} else {
			/*
			 * QuickSearch Cache is valid, so use local storage quickSearchData.
			 */
			quickSearchData = getLocalStorage('quickSearchData');

			/*
			 * Call addSearchEventListener to setup the search.
			 */
			this.addSearchEventListener(quickSearchData);
		}

		/* Check if this.collapsingContainer was found */
		if (this.collapsingContainer) {
			/*
			 * Create a Mutation Observer instance to look for the collapse/opening of the container
			 * via the class 'show'.
			 */
			// eslint-disable-next-line no-shadow, no-unused-vars
			const observer = new MutationObserver((mutationsList, observer) => {
				mutationsList.forEach((mutation) => {
					/* Check if the 'show' class has been added or removed */
					if (mutation.attributeName === 'class') {
						const classList = mutation.target.classList;

						/* Check if the 'show' class is present, indicating the collapse is done */
						if (classList.contains('show')) {
							/* Set focus on the searchInput field */
							this.searchInput.focus();
						} else if (!classList.contains('show')) {
							/* Remove focus on the searchInput field */
							this.searchInput.blur();
						}
					}
				});
			});

			/*
			 * Define the collapsingToggle for this collapsingContainer
			 */
			this.collapsingToggle = document.querySelector(
				`[data-mdb-target="#${this.collapsingContainer.id}"]`
			);

			/*
			 * Configure and start observing the target node for changes in attributes
			 */
			const config = { attributes: true };
			observer.observe(this.collapsingContainer, config);
		}
	}

	/**
	 * Sets up and adds the event listeners for the quicksearch functionality.
	 *
	 * @param {Array} quickSearchData - The data used for performing quick searches.
	 */
	addSearchEventListener(quickSearchData) {
		/*
		 * Define searchResults array
		 */
		let searchResults = [];

		/*
		 * Define selectedIndex
		 * The selcted index of the li elements of the search results.
		 */
		let selectedIndex = -1;

		/*
		 * Define liElements.
		 * The list items of the search results.
		 */
		let liElements;

		/*
		 * Hides the results container by setting its display style to 'none'.
		 */
		const hideResults = () => {
			this.resultsContainerElement.style.display = 'none';
		};

		/*
		 * Shows the results container by setting its display style to 'block'.
		 */
		const showResults = () => {
			/* Resets the arrow key selections when the results are shown. */
			selectedIndex = -1;

			/* Show the results. */
			this.resultsContainerElement.style.display = 'block';

			/*
			 * Set the dimensions of the results container.
			 * Based on the size of the search container and viewport height.
			 */
			this.setQuickSearchResultsDimensions();
		};

		/*
		 * Displays a "Press Enter to search" message within the results container.
		 */
		const showPressEnterMessage = () => {
			showResults();
			this.resultsContainerElement.innerHTML =
				'<div class="bsu-quicksearch-message fw-bolder mt-0">Press Enter to search.</div>';
		};

		/**
		 * Handles keydown events for quick search results selection interaction.
		 *
		 * This function responds to specific keydown events, updating the highlighting
		 * of search results and enabling navigation when the Enter key is pressed.
		 *
		 * @param {KeyboardEvent} event - The keydown event object.
		 */
		const quickSearchKeyDownEventListener = (event) => {
			/*
			 * Define resultsContainerComputedStyle
			 * Gets the computed styles so the display status of the element can be checked.
			 */
			const resultsContainerComputedStyle = window.getComputedStyle(
				this.resultsContainerElement
			);

			/*
			 * Limit the keydown event checking to only when the searchresults are showing.
			 */
			if (
				resultsContainerComputedStyle.getPropertyValue('display') ===
				'block'
			) {
				liElements =
					this.resultsContainerElement.querySelectorAll('li');

				/**
				 * Updates the selection of a list item based on the provided index and key.
				 *
				 * @param {number} index      - The index of the list item to be selected.
				 * @param {string} keyPressed - The key representing the direction of selection ('ArrowUp' or 'ArrowDown').
				 */
				function selectListItem(index, keyPressed) {
					/* Remove the previous selectedIndex highlight. */
					if (selectedIndex >= 0 && liElements[selectedIndex]) {
						liElements[selectedIndex].classList.remove(
							'bsu-quicksearch-selected'
						);
					}

					/*
					 * Check which direction the user wants to go in the list with the arrow keys, and make the appropriate adjustments.
					 */
					if (selectedIndex === -1 && keyPressed === 'ArrowUp') {
						selectedIndex = index = liElements.length - 1;
						liElements[index].classList.add(
							'bsu-quicksearch-selected'
						);
					} else if (
						selectedIndex === -1 &&
						keyPressed === 'ArrowDown'
					) {
						selectedIndex = index = 0;
						liElements[index].classList.add(
							'bsu-quicksearch-selected'
						);
					} else if (
						selectedIndex === liElements.length - 1 &&
						keyPressed === 'ArrowDown'
					) {
						selectedIndex = index = 0;
						liElements[index].classList.add(
							'bsu-quicksearch-selected'
						);
					} else if (
						selectedIndex === 0 &&
						keyPressed === 'ArrowUp'
					) {
						selectedIndex = index = liElements.length - 1;
						liElements[index].classList.add(
							'bsu-quicksearch-selected'
						);
					} else {
						selectedIndex = index;
						liElements[index].classList.add(
							'bsu-quicksearch-selected'
						);
					}
				}

				/*
				 * Handle the 4 keydown events we want:
				 * ArrowDown
				 * ArrowUp
				 * Enter
				 * Escape
				 */
				if (event.key === 'ArrowDown') {
					event.preventDefault();
					selectListItem(selectedIndex + 1, 'ArrowDown');
				} else if (event.key === 'ArrowUp') {
					event.preventDefault();
					selectListItem(selectedIndex - 1, 'ArrowUp');
				} else if (event.key === 'Enter') {
					if (selectedIndex >= 0) {
						event.preventDefault();

						const link =
							liElements[selectedIndex].querySelector('a');
						if (link) {
							window.location.href = link.getAttribute('href');
						}
					}
				} else if (event.key === 'Escape') {
					hideResults();
				}
			}
		};

		/*
		 * Manages the keydown event listeners for search results.
		 */
		const manageSearchResultsKeydownEventListeners = () => {
			/* Set the selectedIndex to -1 so that no results are selected to start with */
			selectedIndex = -1;

			/* Remove the old event listener */
			document.removeEventListener(
				'keydown',
				quickSearchKeyDownEventListener
			);

			/* Add a new event listener with the updated content */
			document.addEventListener(
				'keydown',
				quickSearchKeyDownEventListener
			);
		};

		/*
		 * Handles user input in the search input field, performs search, and displays results.
		 */
		const handleInput = () => {
			/*
			 * Get the search term.
			 */
			this.searchTerm = this.searchInput.value.toLowerCase().trim();

			/* Only Seach if this.searchTerm has enough characters. */
			if (this.searchTerm.length >= 2) {
				/*
				 * Set the searchResults.
				 */
				searchResults = this.searchInData(
					quickSearchData,
					this.searchTerm
				);

				/*
				 * If there are searchResults
				 */
				if (searchResults.length > 0) {
					/* Build the results to be displayed from the searchResults data */
					this.buildResults(searchResults);

					/*
					 * Show the searchResults.
					 */
					showResults();
					manageSearchResultsKeydownEventListeners();
				}
			} else {
				/*
				 * Hide the searchResults.
				 */
				hideResults();
			}

			/*
			 * Show the "Press Enter to search" message.
			 */
			if (searchResults.length === 0 && this.searchTerm.length >= 2) {
				showPressEnterMessage();
			}
		};

		/*
		 * Add the this.searchInput 'input' addEventListener
		 */
		this.searchInput.addEventListener('input', () => handleInput());

		/*
		 * Add the this.searchInput 'focusout' addEventListener.
		 * Only calls hideResults if the event.relatedTarget is not a child/grandchild of the this.resultsContainerElement.
		 */
		this.searchInput.addEventListener('focusout', (event) => {
			const focusoutElement = event.relatedTarget;
			if (!this.resultsContainerElement.contains(focusoutElement)) {
				hideResults();
			}
		});

		/*
		 * Add the this.searchInput 'focus' addEventListener.
		 */
		this.searchInput.addEventListener('focus', () => handleInput());
	}

	/**
	 * Finds and returns the closest parent element with a specified class name.
	 *
	 * @param {Element} element   - The starting element to search from.
	 * @param {string}  className - The class name to search for in the parent elements.
	 *
	 * @return {Element|null} - The closest parent element with the specified class name, or null if not found.
	 */
	findParentWithClass(element, className) {
		/* Start from the current element */
		let currentElement = element;

		/*
		 * Loop until we reach the top of the DOM (document body).
		 */
		while (
			currentElement &&
			!currentElement.classList.contains(className)
		) {
			/*
			 * Move up to the parent element
			 */
			currentElement = currentElement.parentElement;
		}

		/*
		 * Return the found element (or null if not found)
		 */
		return currentElement;
	}

	/* eslint-disable */
	/**
	 * Function to build search results in the specified container
	 * @param {Array} results - An array of search results.
	 *
	 * results example format:
	 *
	 * [
	 *      {
	 *           "title": "Category Title",
	 *           "items": [
	 *                {
	 *                     "title": "Item Title",
	 *                     "url": "https://url/of/the/item/",
	 *                     "keywords": "comma,seperated,list,of,keywords",
	 *                     "score": int(item weight score within it's category)
	 *                },
	 *                ...
	 *           ]
	 *      },
	 *      ...
	 * ]
	 *
	 * @returns {void}
	 */
	/* eslint-enable */

	buildResults(results) {
		/*
		 * Remove any existing results content.
		 */
		this.resultsContainerElement.innerHTML = '';

		/*
		 * Loop through the results.
		 */
		results.forEach((category) => {
			/*
			 * Build the Category Title if there is one and append it to the resultsContainerElement.
			 */
			if (category.title) {
				const categoryTitle = document.createElement('h2');
				categoryTitle.textContent = category.title;
				categoryTitle.classList.add(
					'bsu-quicksearch-category-title',
					'text-white',
					'fs-5',
					'ff-sans',
					'fw-bolder',
					'border-bottom',
					'border-light',
					'mt-0',
					'mb-1'
				);
				this.resultsContainerElement.appendChild(categoryTitle);
			}

			/*
			 * Build the list, looping through the items.
			 * Add the list item mouseover/mouseout events.
			 * Append it to the resultsContainerElement.
			 */
			const list = document.createElement('ul');

			list.classList.add('list-unstyled');

			category.items.forEach((result) => {
				const listItem = document.createElement('li');
				listItem.classList.add('ps-0', 'ps-md-3');
				listItem.innerHTML = `<a class="text-decoration-none text-white d-block fw-normal" tabindex="0" href="${result.url}">${result.title}</a>`;
				list.appendChild(listItem);

				/*
				 * Add mouseover eventlistener to add the class bsu-quicksearch-selected.
				 */
				listItem.addEventListener('mouseover', function () {
					this.classList.add('bsu-quicksearch-selected');
				});

				/*
				 * Add mouseout eventlistener to remove the class bsu-quicksearch-selected.
				 */
				listItem.addEventListener('mouseout', function () {
					this.classList.remove('bsu-quicksearch-selected');
				});
			});

			this.resultsContainerElement.appendChild(list);
		});
	}

	/**
	 * Function to search the data for the given term
	 * @param {Object} data  - The data to search in.
	 * @param {string} terms - The search terms.
	 *
	 * @return {Array} - An array of search results.
	 */
	searchInData(data, terms) {
		const results = [];

		/*
		 * Split the search term into an array of individual terms.
		 */
		const searchTerms = terms.toLowerCase().split(' ');

		for (const category in data) {
			const categoryData = data[category].data;
			const categoryTitle = data[category].title || '';
			const categoryResults = categoryData
				.map((item) => {
					const title = item.title.toLowerCase();
					const keywords = (item.keywords || '').toLowerCase();

					/*
					 * Define matchScore
					 * Check if any of the search terms are found in the title or keywords
					 */
					const matchScore = searchTerms.reduce((score, term) => {
						if (title.includes(term)) {
							/* Increase the score for a match in title */
							score += 2;
						}
						if (keywords.includes(term)) {
							/*
							 * Increase the score for a match in keywords.
							 */
							score += 1;
						}
						return score;
					}, 0);

					return {
						title: item.title,
						url: item.url,
						keywords: item.keywords,
						score: matchScore,
					};
				})
				.filter(
					(result) => result.score > 0
				); /* Filter out items with no match */

			if (categoryResults.length > 0) {
				/* Sort items within each category */
				categoryResults.sort((a, b) => {
					/* Sort based on the item score (descending order) */
					if (b.score !== a.score) {
						return b.score - a.score;
					}

					/* Secondary sort based on the item title (ascending order) */
					return a.title.localeCompare(b.title);
				});

				results.push({
					title: categoryTitle,
					items: categoryResults,
				});
			}
		}

		/*
		 * Return the serch results.
		 */
		return results;
	}

	/*
	 * Set the dimensions of the resultsContainerElement.
	 * Based on the size of the parentContainer and viewport height.
	 */
	setQuickSearchResultsDimensions() {
		/*
		 * Reset the height of this.resultsContainerElement.
		 * This allows for the calculations of the height to be based of the height
		 * of the content inside.
		 */
		this.resultsContainerElement.style.height = 'auto';

		/*
		 * Define the viewportHeight
		 */
		const viewportHeight =
			window.innerHeight || document.documentElement.clientHeight;

		/*
		 * Define searchWrapperWidth
		 */
		const searchWrapperWidth = this.parentContainerElement.clientWidth;

		/*
		 * Define searchInputElementSizePosition
		 */
		const searchInputElementSizePosition =
			this.searchInput.getBoundingClientRect();

		/*
		 * Define positionFromTop - position of this.searchInput from the top of the viewport
		 */
		const positionFromTop = searchInputElementSizePosition.top;

		/*
		 * Define updatedResultsContainerElementHeight
		 */
		const updatedResultsContainerElementHeight =
			viewportHeight -
			positionFromTop -
			this.searchInput.clientHeight * 2;

		/*
		 * Check if the height of this.resultsContainerElement needs to be changed
		 */
		if (
			parseInt(this.resultsContainerElement.clientHeight) >
			parseInt(updatedResultsContainerElementHeight)
		) {
			/* Set this.resultsContainerElement height */
			this.resultsContainerElement.style.height =
				updatedResultsContainerElementHeight + 'px';
		}
		/*
		 * Set the width of the resultsContainerElement
		 */
		this.resultsContainerElement.style.width = searchWrapperWidth + 'px';
	}
}

/*
 * **************************************************************************************************
 * End Quick Search
 * **************************************************************************************************
 */

/*
 * Add the eventlistener for the browser.
 * Checking for process.env.NODE_ENV prevents instantiating the class during unit testing.
 */
if (process.env.NODE_ENV !== 'test') {
	document.addEventListener('DOMContentLoaded', function () {
		/*
		 * Get all the elements with the class 'bsu-quicksearch'.
		 */
		const quickSearch = document.querySelectorAll('.bsu-quicksearch');

		quickSearch.forEach(function (element) {
			/*
			 * Instantiate QuickSearch.
			 */
			// eslint-disable-next-line no-unused-vars
			const headerSearch = new QuickSearch({
				searchInput: element,
			});
		});
	});
}
